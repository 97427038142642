import { css } from '@emotion/react'
import { Stack } from 'fitify-ui-onboarding/src/components'
import { useViewport, useWindowSize } from 'fitify-ui-onboarding/src/hooks'
import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useInView } from 'react-intersection-observer'

import { StyledThresholdBackground } from './Threshold.Style'

import { ThresholdProps } from '.'

const ThresholdWrapper = ({ hasBackground, children }: ThresholdProps) => {
  const { windowWidth } = useWindowSize()
  const { height } = useViewport()
  const [containerWidth, setContainerWidth] = useState<number>(400)
  const [containerHeight, setContainerHeight] = useState<number>(0)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const childRefs = useRef<HTMLDivElement[]>([])

  const { ref: inViewRef, inView } = useInView({
    threshold: 1,
    initialInView: true,
  })
  const thresholdHeightDefault = 300
  const isThresholdActive = !inView && height > thresholdHeightDefault

  const setRefs = useCallback(
    (node: any) => {
      containerRef.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  useEffect(() => {
    const heights = childRefs.current.map((ref) => ref?.offsetHeight || 0)
    const sumOfHeights = heights.reduce((acc, height) => acc + height, 0)

    setContainerWidth(containerRef?.current?.offsetWidth || 0)
    setContainerHeight(sumOfHeights)
  }, [childRefs, containerRef, windowWidth])

  const childrenWithProps = Children.map(children, (child, index) => {
    if (isValidElement(child)) {
      return cloneElement(child as ReactElement, {
        ref: (el: HTMLDivElement) => (childRefs.current[index] = el),
        style: isThresholdActive
          ? {
              position: 'fixed',
              bottom: `calc(var(--s-continue-button))`,
              width: containerWidth,
              zIndex: `var(--z-index-threshold-button)`,
              maxWidth: `var(--w-content)`,
            }
          : {
              maxWidth: `var(--w-content)`,
            },
      })
    }
    return child
  })

  return (
    <Stack
      ref={setRefs}
      css={css`
        justify-content: end;
        align-items: center;
        min-height: ${containerHeight}px;
        margin-top: 2rem;
      `}
    >
      {!hasBackground ? (
        <StyledThresholdBackground
          height={containerHeight}
          isActive={isThresholdActive}
        />
      ) : null}
      {childrenWithProps}
    </Stack>
  )
}

export default ThresholdWrapper
